import React from "react";
import { useNavigate } from "react-router-dom";
import Price from "./Price";

function ProductCard({ product }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/producto/${product.id}`)}
      className="cursor-pointer shineBor diffBg p-4 rounded-2xl h-fit w-[300px] relative"
    >
      {product.discount > 0 && <div className="rounded-full bg-black absolute w-12 h-12 flex justify-center items-center top-5 left-6">
        <span>-{product.discount}%</span>
      </div>}
      <div>
        <img
          src={`${process.env.REACT_APP_CDN}/${
            product.images ? JSON.parse(product.images)[0] : ""
          }`}
          alt={product.name}
          className="w-full rounded max-h-[150px] object-cover min-h-[150px]"
        />
        <div className="mt-3 min-h-[160px] flex flex-col justify-between">
          <div>
            <span className="">{product.name}</span> <br />
            <span className="secondFont text-sm">
              {product.shortDescription}
            </span>{" "}
          </div>
          <ul className=" mb-3">
            {product.details
              .split(",")
              .slice(0, 4)
              .map((item, i) => (
                <li key={i} className="ultraSmall diffWhite secondFont">
                  {item}
                </li>
              ))}
          </ul>

          <Price
            className={
              "bold secondFont mainBg p-2 text-center rounded relative"
            }
            internationalPrice={product.internationalPrice}
            price={product.price}
            discount={product.discount}
          />
        </div>
      </div>
    </div>

    // <div
    //   onClick={() => navigate(`/producto/${product.id}`)}
    //   className="cursor-pointer flex flex-col items-center w-[300px] "
    // >
    //   <img
    //     src={`${process.env.REACT_APP_API}/files/${
    //       product.images ? JSON.parse(product.images)[0] : ""
    //     }`}
    //     alt=""
    //     className="w-150px rounded-2xl h-[150px] object-cover"
    //   />
    //   <span>{product.name}</span> <br />
    //   <span className="secondFont text-center small diffWhite">
    //     {product.shortDescription}
    //   </span>{" "}
    //   <br />
    //   <Price className={"mainColor secondFont"} internationalPrice={product.internationalPrice} price={product.price} />
    // </div>
  );
}

export default ProductCard;

import React, { useContext } from "react";
import { CountryContext } from "../context/Country";

function Price({ price, internationalPrice, discount, className }) {
  const { country } = useContext(CountryContext);
  const international = internationalPrice ? internationalPrice : price;

  const internationalTotal = international - (international * discount || 0) / 100;
  const total = price - (price * discount || 0) / 100;

  if (country === "CR")
    return (
      <div className={`${className} flex flex-col `}>
        {discount > 0 && (
          <span className="line-through text-xs text-gray-300 font-normal">
            {price} USD
          </span>
        )}
        <span>{total} USD</span>
      </div>
    );
  else
    return (

      <div className={`${className} flex flex-col`}>
        {discount > 0 && (
           <span className="line-through text-xs text-gray-300 font-normal">
           {international} USD
         </span>
        )}
        <span>{internationalTotal } USD</span>
      </div>
     
    );
}

export default Price;

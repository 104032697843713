import React, { useContext, useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Input } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faLink,
  faLocation,
  faMap,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import CountriesSelection from "../components/CountriesSelection";
import { toast } from "sonner";
import { CountryContext } from "../context/Country";
import api from "../lib/api";
import { useNavigate } from "react-router-dom";

function NewDealer() {
  const { country } = useContext(CountryContext);
  const navigate = useNavigate();
  const [dealer, setDealer] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    address: "",
    website: "",
    googleMaps: "",
    whatsapp: "",
    file: null,
  });

  const handleChange = (e) => {
    setDealer({
      ...dealer,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    setDealer({
      ...dealer,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    //validate

    if (
      dealer.name.trim() === "" ||
      dealer.email.trim() === "" ||
      dealer.phone.trim() === "" ||
      dealer.city.trim() === "" ||
      dealer.address.trim() === "" ||
      dealer.website.trim() === "" ||
      dealer.googleMaps.trim() === "" ||
      dealer.whatsapp.trim() === "" ||
      dealer.file === null
    ) {
      toast.error("Campos requeridos vacíos");
      return;
    }

    //send to api

    try {
      const formData = new FormData();
      formData.append("file", dealer.file);
      formData.append("name", dealer.name);
      formData.append("email", dealer.email);
      formData.append("phone", dealer.phone);
      formData.append("city", dealer.city);
      formData.append("country", country);
      formData.append("address", dealer.address);
      formData.append("website", dealer.website);
      formData.append("googleMaps", dealer.googleMaps);
      formData.append("whatsapp", dealer.whatsapp);
      await api.post("/dealers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      navigate("/");
      toast.success(
        "Formulario enviado con éxito, pronto nos pondremos en contacto con usted",
        {
          duration: 10000,
        }
      );
    } catch (error) {
      toast.error("Error al enviar el formulario");
      return;
    }
  };

  return (
    <>
      <Nav />
      <main className="flex justify-center min-h-screen pt-16 secondFont">
        <form action="" className="flex flex-wrap p-4 md:p-0 md:w-1/2 gap-4">
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            name="name"
            label="Nombre"
            placeholder="Nombre del negocio"
          />
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            name="email"
            type="email"
            label="Email"
            placeholder="you@example.com"
            labelPlacement="inside"
            startContent={
              <FontAwesomeIcon icon={faEnvelope} className="text-gray-500" />
            }
          />
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            startContent={
              <FontAwesomeIcon icon={faPhone} className="text-gray-500" />
            }
            name="phone"
            label="Teléfono"
            placeholder="Teléfono del local"
          />
          <div className="flex items-center gap-2">
            <label htmlFor="country" className="text-gray-500">
              País
            </label>
            <CountriesSelection />
          </div>
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            startContent={
              <FontAwesomeIcon icon={faBuilding} className="text-gray-500" />
            }
            name="city"
            label="Ciudad"
            placeholder="Ciudad"
          />
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            startContent={
              <FontAwesomeIcon icon={faLocation} className="text-gray-500" />
            }
            name="address"
            label="Dirección"
            placeholder="Dirección"
          />
          <Input
            onInput={(e) => handleChange(e)}
            type="text"
            startContent={
              <FontAwesomeIcon icon={faLink} className="text-gray-500" />
            }
            name="website"
            label="Sitio web"
            placeholder="Sitio web (opcional)"
          />
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            startContent={
              <FontAwesomeIcon icon={faMap} className="text-gray-500" />
            }
            name="googleMaps"
            label="Google Maps"
            placeholder="Link Google Maps"
          />
          <Input
            onInput={(e) => handleChange(e)}
            isRequired
            type="text"
            startContent={
              <FontAwesomeIcon icon={faWhatsapp} className="text-gray-500" />
            }
            name="whatsapp"
            label="Whatsapp"
            placeholder="Whatsapp"
          />
          <Input
            onInput={(e) => handleFile(e)}
            isRequired
            type="file"
            name="file"
            label="Logo (5MB max)"
            placeholder="Logo "
            accept="image/*"
          />
          <button
            onClick={handleSubmit}
            type="button"
            className="secondBg text-white py-2 px-4 rounded-xl hover:bg-opacity-90 transition-all duration-200"
          >
            Enviar
          </button>
        </form>
      </main>
      <Footer />
    </>
  );
}

export default NewDealer;

import { useContext } from "react";
import { CartContext } from "../context/Cart";
import { CountryContext } from "../context/Country";
import { useNavigate } from "react-router-dom";
import apiv2 from "../lib/apiv2";
import { toast } from "sonner";

const usePayPal = () => {
    const { cart, clearCart } = useContext(CartContext);
  const { country } = useContext(CountryContext);
  const navigate = useNavigate();
  async function createOrder() {
    const { data } = await apiv2.post("/payment/create", { cart, country });
    return data.id;
  }

  async function onApprove(data) {
    try {
      await apiv2.post("/payment/capture", {
        orderID: data.orderID,
      });
      toast.loading("Procesando pago");

      setTimeout(() => {
        toast.success("Pago realizado con éxito");
        clearCart();
        navigate("/success");
      }, 3000);
    } catch (error) {
      toast.error("Proceso de pago fallido");
    }
  }

  function onError(err) {
    console.log(err);
    toast.error("Proceso de pago fallido");
  }

  function onCancel() {
    toast.warning("Proceso de pago cancelado");
  }

    return { createOrder, onApprove, onError, onCancel };
}

export default usePayPal
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import usePayPal from '../hooks/usePayPal';

function PayPalBtn() {
  const { onCancel, onError, createOrder, onApprove } = usePayPal();

  return (
    <PayPalButtons
      onCancel={onCancel}
      onError={onError}
      createOrder={createOrder}
      onApprove={onApprove}
      style={{ layout: 'vertical' }}
    />
  );
}

export default PayPalBtn;

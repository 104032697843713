import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import PayPalBtn from "../components/PayPalBtn";
import CountriesSelection from "../components/CountriesSelection";
import { Button, Divider } from "@nextui-org/react";
import Price from "../components/Price";
import { CartContext } from "../context/Cart";
import { CountryContext } from "../context/Country";

function PaypalBtn() {
  const { getCartTotal, getDeliveryTotal, cart, getIsValidCart } = useContext(CartContext);
  const [countryConfirm, setCountryConfirm] = useState(false);
  const { country } = useContext(CountryContext);

  if (cart.length === 0 && !getIsValidCart()) {
    return <Navigate to={"/productos"} />;
  }

  return (
    <main className="h-fit min-h-screen relative whiteBg flex w-screen mb-flex-col items-center justify-around mb-p-8">
      <Link
        className="secondColor absolute top-2 left-2 secondFont p-4 rounded-xl"
        to={"/productos"}
      >
        <FontAwesomeIcon className="secondColor pr-2" icon={faArrowLeft} />
        Volver
      </Link>
      <div className="flex flex-col items-center w-1/4 mb-full  justify-center gap-4 md:px-4">
        <img className="w-[90px]  h-[90px] rounded-full" src="st.jpg" alt="" />
        <strong className="black secondFont">
          {!countryConfirm ? "Confirmar país" : "Finalizar pago"}
        </strong>
      </div>
      <div className="w-1/4 overflox-y-scroll  mb-full">
        {!countryConfirm ? (
          <ConfirmCountry setCountryConfirm={setCountryConfirm} />
        ) : (
          <div>
            <div className="mb-8">
              <strong className="black secondFont">Resumen</strong>
              <div className="flex justify-between">
                <p className="black small secondFont">Subtotal</p>
                <Price
                  price={getCartTotal()}
                  className={"black secondFont small"}
                />
              </div>

              <div className="flex justify-between">
                <p className="black small secondFont">Envío</p>
                <Price
                  price={getDeliveryTotal()}
                  className={"black secondFont small"}
                />
              </div>

              <div className="flex justify-between">
                <p className="black small secondFont">Otros cargos</p>
                <Price price={0} className={"black secondFont small"} />
              </div>

              <div className="flex justify-between">
                <p className="black small secondFont">Total</p>
                <Price
                  price={getDeliveryTotal() + getCartTotal()}
                  className={"black secondFont small"}
                />
              </div>

              {country !== "CR" && (
                <p className="black small secondFont mt-3 diffBase">
                  * El precio de envío no incluye costo de aduana y otros cargos
                  que DHL pueda cobrar.
                </p>
              )}
              <Divider className="my-4" />
            </div>
            <PayPalBtn />
          </div>
        )}
      </div>
    </main>
  );
}

function ConfirmCountry({ setCountryConfirm }) {
  const { country, isValidCountry } = useContext(CountryContext);
  const { isValidCart, getIsValidCart } = useContext(CartContext);
  return (
    <div className="black secondFont ">
      <div className="flex gap-4 flex-col mb-4">
        <strong>¿El país de destino es el correcto?</strong>
        {country === "CR" ? (
          <p>
            El envío se realizará por medio de Correos de Costa Rica en un plazo
            de <strong>2</strong> a <strong>3</strong> días hábiles.
          </p>
        ) : (
          <p>
            El envío se realizará por medio de DHL Express en un plazo de{" "}
            <strong>3</strong> a <strong>5</strong> días hábiles.
          </p>
        )}
        <CountriesSelection />
        {!isValidCountry() && (
          <span className="danger ultraSmall">
            El país seleccionado no es elegible para la compra en línea, ponte
            en contacto con nosotros para concreatar la entrega
          </span>
        )}
        {!isValidCart && (
          <span className="danger ultraSmall">
            El carrito de compras no es válido, hay productos que no tienen
            disponibilidad
          </span>
        )}
      </div>

      <div>
        <strong>¿Donde ingreso mis datos de envío?</strong>
        <p className="small">
          Los datos de envío se ingresan en el formulario de PayPal, una vez que
          se realiza el pago, se le envía un correo de confirmación con los
          datos de envío y el número de seguimiento de su producto.{" "}
          <span className="danger">
            Si los datos de envío no pertencecen al país seleccionado, la
            entrega no podrá ser realizada.
          </span>
        </p>
      </div>

      {isValidCountry() && isValidCart && (
        <Button
          onPress={() => {
            if (getIsValidCart()) {
              setCountryConfirm(true);
            } 
          }}
          className="secondBg secondFont p-4 mt-4 rounded-xl white"
        >
          Continuar con el pago
        </Button>
      )}
    </div>
  );
}

export default PaypalBtn;
